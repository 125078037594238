export * from '@zaber/serverless-service-definitions';
export var SimulationType;
(function (SimulationType) {
    SimulationType["Public"] = "public";
    SimulationType["Private"] = "private";
})(SimulationType || (SimulationType = {}));
export var AsciiCommEventType;
(function (AsciiCommEventType) {
    AsciiCommEventType[AsciiCommEventType["Request"] = 0] = "Request";
    AsciiCommEventType[AsciiCommEventType["Response"] = 1] = "Response";
})(AsciiCommEventType || (AsciiCommEventType = {}));
export var SimulationMoveTypes;
(function (SimulationMoveTypes) {
    SimulationMoveTypes["Home"] = "home";
    SimulationMoveTypes["ToMin"] = "toMin";
    SimulationMoveTypes["ToMax"] = "toMax";
    SimulationMoveTypes["Stop"] = "stop";
})(SimulationMoveTypes || (SimulationMoveTypes = {}));
