import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { Button, Text, Modal, Icons, AnimationClasses } from '@zaber/react-library';
import { useActions } from '../utils';
import { actions as actionDefinitions } from './actions';
import { selectAccessTokenIdsBeingDeleted } from './selectors';
const TokenDeletionPopupContent = ({ tokenId, tokenName, error }) => {
    const actions = useActions(actionDefinitions);
    const accessTokensBeingDeleted = useSelector(selectAccessTokenIdsBeingDeleted);
    if (error) {
        return (_jsxs("div", Object.assign({ className: "delete-access-token-popup-content" }, { children: [_jsxs("div", { children: [_jsx(Text, { children: "There was an error deleting the access token \u00A0" }, void 0), _jsx(Text, Object.assign({ e: Text.Emphasis.Bold, f: Text.Family.Mono }, { children: tokenName }), void 0), _jsxs(Text, { children: [". ", error, " Please try again. If the error persists refresh the page or sign out and sign back in again."] }, void 0)] }, void 0), _jsxs("div", Object.assign({ className: "button-container" }, { children: [_jsx(Button, Object.assign({ onClick: () => actions.deleteAccessToken(tokenId) }, { children: "Try Again" }), void 0), _jsx(Button, Object.assign({ color: "grey", onClick: () => {
                                actions.accessTokenDeletionError(tokenId);
                                actions.chooseTokenForDeletion(null);
                            } }, { children: "Cancel" }), void 0)] }), void 0)] }), void 0));
    }
    if (accessTokensBeingDeleted.includes(tokenId)) {
        return (_jsxs("div", Object.assign({ className: "deleting-token" }, { children: [_jsx(Icons.Gear, { className: AnimationClasses.Rotation }, void 0), _jsx(Text, { children: "Deleting Access Token" }, void 0)] }), void 0));
    }
    return (_jsxs("div", Object.assign({ className: "delete-access-token-popup-content" }, { children: [_jsx(Text, { children: "Deleted Access Tokens cannot be recovered. Any existing applications or scripts that use this token will be unable to access any Zaber Software Services." }, void 0), _jsxs("div", { children: [_jsx(Text, { children: "Are you sure you want to " }, void 0), _jsx(Text, Object.assign({ e: Text.Emphasis.Bold }, { children: "permanently delete" }), void 0), _jsxs(Text, { children: [" the token named \"", tokenName, "\"?"] }, void 0)] }, void 0), _jsxs("div", Object.assign({ className: "button-container" }, { children: [_jsx(Button, Object.assign({ onClick: () => actions.deleteAccessToken(tokenId), "data-testid": "delete-button" }, { children: "Delete" }), void 0), _jsx(Button, Object.assign({ color: "grey", onClick: () => actions.chooseTokenForDeletion(null) }, { children: "Cancel" }), void 0)] }), void 0)] }), void 0));
};
export const TokenDeletionPopup = ({ tokenId, tokenName, error }) => {
    const actions = useActions(actionDefinitions);
    return _jsx(Modal, Object.assign({ headerIcon: error ? _jsx(Icons.ErrorFault, {}, void 0) : _jsx(Icons.ErrorWarning, {}, void 0), headerText: "Access Token Deletion", onRequestClose: () => actions.chooseTokenForDeletion(null), small: true }, { children: _jsx(TokenDeletionPopupContent, { tokenId: tokenId, tokenName: tokenName, error: error }, void 0) }), void 0);
};
