import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AnimationClasses, Button, Checkbox, Icons, Input, Modal, Text, TextArea } from '@zaber/react-library';
import { useActions } from '@zaber/toolbox/lib/redux';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import isEmail from 'validator/es/lib/isEmail';
import { useWindowEvent } from '../app_components';
import { IconMessage } from '../components/IconMessage';
import { Survey } from '../components/Survey';
import { actions as actionDefinitions } from './actions';
import { selectFeedbackEmail, selectFeedbackState, selectFeedbackModalState, selectFeedbackText, selectFeedbackAnonymous, } from './selectors';
function useCloseFeedbackOnEscape() {
    const actions = useActions(actionDefinitions);
    useWindowEvent('keydown', ({ key }) => {
        if (key === 'Escape') {
            actions.close();
        }
    });
}
const Gather = ({ actions }) => {
    const anonymous = useSelector(selectFeedbackAnonymous);
    const email = useSelector(selectFeedbackEmail);
    const text = useSelector(selectFeedbackText);
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [textInvalid, setTextInvalid] = useState(false);
    useCloseFeedbackOnEscape();
    return _jsxs(_Fragment, { children: [_jsx(Survey, {}, void 0), _jsx(Text, { children: "Do you have a suggestion or have you found a bug? Let us know in the field below." }, void 0), _jsx(TextArea, { value: text, onValueChange: actions.setText, title: "Input feedback" }, void 0), textInvalid && _jsx(IconMessage, Object.assign({ color: "red", icon: "ErrorFault" }, { children: "Feedback text can not be empty" }), void 0), _jsx(Checkbox, { labelContent: "Send feedback anonymously", checked: anonymous, onChecked: actions.setAnonymous }, void 0), !anonymous && _jsxs(_Fragment, { children: [_jsx(Input, { labelContent: "Email", value: email, onValueChange: actions.setEmail, title: "Input email" }, void 0), emailInvalid && _jsx(IconMessage, Object.assign({ color: "red", icon: "ErrorFault" }, { children: "Please provide a valid email address" }), void 0)] }, void 0), _jsx("div", { className: "spacer" }, void 0), _jsx(Button, Object.assign({ title: "Send feedback", onClick: () => {
                    const emailIsValid = anonymous ? true : isEmail(email !== null && email !== void 0 ? email : '');
                    const textIsValid = !!text;
                    setEmailInvalid(!emailIsValid);
                    setTextInvalid(!textIsValid);
                    emailIsValid && textIsValid && actions.sendFeedback();
                } }, { children: "Send" }), void 0)] }, void 0);
};
const Info = ({ feedbackModalState, actions }) => {
    const { feedbackError } = useSelector(selectFeedbackState);
    useCloseFeedbackOnEscape();
    switch (feedbackModalState) {
        case 'sending':
            return _jsxs(_Fragment, { children: [_jsx(Icons.Gear, { className: AnimationClasses.Rotation }, void 0), _jsx(Text, Object.assign({ t: Text.Type.Body }, { children: "Sending your feedback" }), void 0)] }, void 0);
        case 'sent':
            return _jsxs(_Fragment, { children: [_jsx(Icons.Confirmation, { className: "confirmation" }, void 0), _jsx(Text, Object.assign({ t: Text.Type.Body }, { children: "We appreciate you taking the time to share your thoughts!" }), void 0)] }, void 0);
        case 'error':
            return _jsxs(_Fragment, { children: [_jsx(Icons.ErrorFault, { className: "send-error" }, void 0), _jsx(Text, Object.assign({ t: Text.Type.Body }, { children: "There was a problem sending your feedback." }), void 0), _jsx(Text, Object.assign({ className: "error-text", t: Text.Type.BodySm, e: Text.Emphasis.Light }, { children: feedbackError }), void 0), _jsx(Button, Object.assign({ onClick: actions.sendFeedback, title: "Send feedback" }, { children: "Try Again" }), void 0)] }, void 0);
    }
};
export const FeedbackModal = () => {
    const actions = useActions(actionDefinitions);
    const feedbackModalState = useSelector(selectFeedbackModalState);
    useEffect(() => {
        const open = () => { actions.open(); };
        window.addEventListener('zaber-open-feedback', open);
        return () => { window.removeEventListener('zaber-open-feedback', open); };
    }, []);
    if (feedbackModalState === 'closed') {
        return null;
    }
    return _jsx(Modal, Object.assign({ className: classNames('feedback-modal', { center: feedbackModalState !== 'gather' }), headerIcon: _jsx(Icons.Feedback, {}, void 0), headerText: "Send Feedback", onRequestClose: actions.close }, { children: feedbackModalState === 'gather' ? _jsx(Gather, { actions: actions }, void 0) : _jsx(Info, { feedbackModalState: feedbackModalState, actions: actions }, void 0) }), void 0);
};
